import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { useI18n } from 'i18n';
import { useMobile } from 'src/utils/useWindowSize';
import HeaderUser from 'src/components/HeaderUser';

import LinkButton from '../LinkButton';
import LogoImage from '../LogoImage';
import AppDrawer from './AppDrawer';
import Nav from './Nav';

export default function Header(): React.ReactElement {
  const theme = useTheme();
  const { locale } = useRouter();
  const { t } = useI18n();
  const mobile = useMobile();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar position="fixed" sx={{ bgcolor: 'primary.main', backgroundImage: 'none', zIndex: 'modal' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label={t('menu')}
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
            }}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Link href={`/${locale}`}>
            <a style={{ marginRight: 30 }}>
              <LogoImage white scale={1.2} />
            </a>
          </Link>
          <Nav />
          <div style={{ flexGrow: 1 }} />
          {!mobile && (
            <LinkButton href="/app/dashboard" variant="contained" aria-label={t('go-to-app')} aria-controls="go-to-app" color="secondary" sx={{ mr: 2 }}>
              {t('go-to-app')}
            </LinkButton>
          )}
          <HeaderUser />
        </Toolbar>
      </AppBar>
      <AppDrawer mobileOpen={mobileOpen} handleDrawerClose={handleDrawerToggle} />
    </>
  );
}
